import React from 'react';
import {Container, createStyles, makeStyles, Theme} from '@material-ui/core';
import {Route, Switch} from 'react-router-dom';

import {apiRoutePrefix} from '../../../RouteComponent';
import Introduction from '../screens/Introduction';
import Connecting from '../screens/Connecting';
import Errors from '../screens/Errors';
import LocationGroups from '../screens/LocationGroups';
import Login from '../screens/Login';
import PurchaseOrders from '../screens/PurchaseOrders';
import Memos from '../screens/Memos';
import Users from '../screens/Users';
import Part from '../screens/Part';
import Product from '../screens/Product';
import ManufactureOrders from '../screens/ManufactureOrders';
import Uoms from '../screens/Uoms';
import Vendors from '../screens/Vendors';
import Inventory from '../screens/Inventory';
import Integrations from '../screens/Integrations';
import ImportExport from '../screens/ImportExport';
import SalesOrders from "../screens/SalesOrders";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        offset: theme.mixins.toolbar
    }),
);

const DocumentContent: React.FC = () => {
    const classes = useStyles();
    const routes = [
        Introduction,
        Connecting,
        Errors,
        Login,
        ImportExport,
        Integrations,
        Inventory,
        LocationGroups,
        ManufactureOrders,
        Memos,
        Part,
        Product,
        PurchaseOrders,
        SalesOrders,
        Uoms,
        Users,
        Vendors,
    ];

    return (
        <main className={ classes.content }>
            <div className={ classes.offset }/>
            <Container>
                <Switch>
                    {
                        routes.map(route => <Route {...route}/>)
                    }
                    <Route exact path={ apiRoutePrefix } component={ Introduction.component }/>
                </Switch>
            </Container>
        </main>
    );
};

export default DocumentContent;
